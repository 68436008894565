import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../context/store-context";
import scalaPayLogo from "../../resources/images/scalapay_logo@2x.png";
import Button from "../button";
import LinedTitle from "../commons/lined-title";
import { getTimeDiff, hasPassedLimitTime, useUrls } from "../utils";
import bellIc from "../../resources/images/icon_notification@1x.svg";
import consIc from "../../resources/images/icon_consegna_gratuita@1x.svg";
import spedIc from "../../resources/images/icon_spedizione_gratuita@1x.svg";
import infIc from "../../resources/images/icon_information@1x.svg";
import { EmailContext } from "../context/email-context";
import fb from "../../resources/images/icon-facebook@1x.svg";
import tw from "../../resources/images/icon-twitter@1x.svg";
import pi from "../../resources/images/icon-pinterest@1x.svg";
import wa from "../../resources/images/icon-watsapp@1x.svg";
import useShopifyProducts from "../use-shopify-products";
import { LanguageContext } from "../../context/language-context";

const BuyInfo = ({ product, tit, type }) => {
  console.log({ type });

  const { price, compareAtPrice } = product.variants[0];
  const percentageDiscount =
    100 - Math.round((100 * parseFloat(price)) / parseFloat(compareAtPrice));

  // ASSUMING 1 VARIANT (SHOPIFY MEANING)
  const [isAvailable, setIsAvailable] = useState(0);
  const storeCtx = useContext(StoreContext);

  const checkAvailabilities = async (productId) => {
    // check for a product which variant is available
    const productLive = await storeCtx.client.product.fetch(productId);
    //console.log({ productLive });
    setIsAvailable(productLive.variants[0].available ? 1 : -1);
  };

  useEffect(() => {
    checkAvailabilities(product.shopifyId);
  }, []);

  const checkVariantAvailabilityAndAddToCart = async (
    productId,
    variantIdx
  ) => {
    const productLive = await storeCtx.client.product.fetch(productId);
    const variant = productLive.variants[variantIdx];
    const isAvailable = variant.available;
    if (isAvailable) {
      storeCtx.addVariantToCart(variant.id, 1);
      // storeCtx.isCartOpen || storeCtx.setCart(true);
    } else {
      setIsAvailable(-2);
    }
  };

  const { email, setEmail } = useContext(EmailContext);
  const [tempEmail, setTempEmail] = useState(email);

  const [esauritoStatus, setEsauritoStatus] = useState("ready");

  const notifyClick = (e) => {
    e.preventDefault();
    // TOOD : register on ac
    Math.random() > 0.5
      ? setEsauritoStatus("submitted")
      : setEsauritoStatus("failed");
  };

  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  return (
    <div>
      <div className="flex gap-2.5 items-baseline">
        {compareAtPrice && (
          <p className="font-bold line-through">€ {compareAtPrice}</p>
        )}
        <p className="font-bold text-ci-red text-2xl">€ {price}</p>
        {compareAtPrice && (
          <div className="h-6 w-14 bg-ci-red font-bold text-white text-sm rounded-ci-badge flex justify-center items-center">
            -{percentageDiscount}%
          </div>
        )}
        <p className="text-ci-gray">{t["iva-inclusa"]}</p>
      </div>
      {/* <div className="flex gap-2 items-center mt-2">
        <p>
          o 3 rate da{" "}
          <span className="font-bold">
            {(parseFloat(price) / 3).toFixed(2)}€
          </span>{" "}
          senza interessi.
        </p>
        <img src={scalaPayLogo} alt="Scalapay" width={96} />
      </div> */}
      <div className="mt-4">
        <div>
          <ExternalProductVariants p={product} tit={tit} type={type} />
          <div className="mt-8">
            {isAvailable > 0 && (
              <div>
                <button
                  onClick={() => {
                    checkVariantAvailabilityAndAddToCart(product.shopifyId, 0);
                  }}
                  className="w-full"
                >
                  <Button
                    className="w-full px-10 py-5 uppercase font-bold"
                    type="red"
                  >
                    {t["aggiungi-carrello"]}
                  </Button>
                </button>
                <SameDayBlock />
              </div>
            )}
            {isAvailable < 0 && (
              <div>
                <div
                  className="ci-button-thi uppercase block text-center py-0"
                  style={{ height: "50px", lineHeight: "50px" }}
                >
                  {t.esaurito}
                </div>
                <div className="my-4">
                  <LinedTitle>
                    <img src={bellIc} alt="" />
                    <p>{t["avvisami-disp"]}</p>
                  </LinedTitle>
                </div>
                {esauritoStatus !== "submitted" && (
                  <form onSubmit={notifyClick}>
                    <div className="relative">
                      <input
                        type="email"
                        placeholder="Email"
                        className={
                          "block border rounded-full w-full pl-8 pr-52 text-base outline-none focus:ring-black focus:ring-1 " +
                          (esauritoStatus === "failed"
                            ? "border-ci-red"
                            : "border-ci-gray-light")
                        }
                        style={{ height: "50px", lineHeight: "50px" }}
                        required
                        value={tempEmail}
                        onChange={(e) => {
                          setTempEmail(e.target.value);
                        }}
                      />

                      <button className="h-full absolute top-0 right-0 w-36 bg-ci-red text-white uppercase rounded-full font-bold">
                        {t.avvisami}
                      </button>
                    </div>
                  </form>
                )}
                {esauritoStatus === "failed" && (
                  <p className="text-center font-bold text-ci-red mt-4 bg-ci-beige-light py-5 rounded-ci-badge">
                    {t.problema}
                  </p>
                )}
                {esauritoStatus === "submitted" && (
                  <p className="text-center font-bold text-white bg-ci-green rounded-ci-badge py-5">
                    {t["ti-avviseremo"]}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="mt-7 border-t border-ci-beige-50 font-bold">
            <p className="flex items-center py-1.5 border-ci-beige-50 border-b gap-4 flex-nowrap">
              <img src={consIc} alt="" className="flex-shrink-0" />
              <p className="flex-grow">{t["consegna-veloce"]}</p>
              <button className="flex-shrink-0">
                <img src={infIc} alt="informazioni" className="pr-5" />
              </button>
            </p>
            <p className="flex items-center py-1.5 border-ci-beige-50 border-b gap-4 flex-nowrap">
              <img src={spedIc} alt="" className="flex-shrink-0" />
              <p className="flex-grow">{t["sped-gratuita"] + "99€"}</p>
              <button className="flex-shrink-0">
                <img src={infIc} alt="informazioni" className="pr-5" />
              </button>
            </p>
          </div>
          {/* <div className="mt-7 flex items-center gap-2">
            {t["condividi-su"]}:
            <img src={fb} alt="facebook link" className="h-6" />
            <img src={tw} alt="twitter link" className="h-6" />
            <img src={pi} alt="pinterest link" className="h-6" />
            <img src={wa} alt="whatsapp link" className="h-6" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

const ExternalProductVariants = ({ p, tit, type }) => {
  const allProds = useShopifyProducts();
  const { t, locale } = useContext(LanguageContext);
  const l = locale;
  const urls = useUrls()[l];

  const sku = p.variants[0].sku.toUpperCase();

  const variants = Object.entries(allProds).filter(
    ([k, v]) => k.slice(0, 10) === sku.slice(0, 10)
  );
  const hasVariants = variants.length > 0;

  let intestazione = t["kit-selezionato"];
  let variantTitle = tit;
  // TODO : update basing on product title part
  switch (type) {
    case "peso":
      intestazione = t["peso"];
      variantTitle = p.variants[0].title;
      break;
    case "colore":
      intestazione = t["colore"];
      variantTitle = p.variants[0].title;
      break;
  }

  return (
    hasVariants && (
      <>
        {intestazione}:<span className="pl-1.5 font-bold">{variantTitle}</span>
        <div className="flex mt-3 gap-5">
          {/* TODO : link different product families */}
          {variants.map(([k, v]) => {
            switch (type) {
              case "image":
                return (
                  <Link
                    to={urls[k.toLowerCase()]}
                    key={k}
                    className={`border border-ci-${
                      k === sku ? "red" : "beige-50"
                    }`}
                    style={{ width: "75px", height: "75px" }}
                  >
                    <img src={v.images[0].src} alt={v.title ?? ""} />
                  </Link>
                );
              case "peso":
                return (
                  <Link
                    to={urls[k.toLowerCase()]}
                    key={k}
                    className={`py-3 px-4 font-bold text-base rounded-full border border-ci-${
                      k === sku ? "red" : "beige-50"
                    }`}
                  >
                    {v.variants[0].title}
                  </Link>
                );
              case "colore":
                return (
                  <Link
                    to={urls[k.toLowerCase()]}
                    key={k}
                    className={`w-12 h-12 grid place-content-center rounded-full border border-${
                      k === sku ? "ci-red" : "transparent"
                    }`}
                  >
                    <div className="w-10 h-10 rounded-full bg-sky-200" />
                  </Link>
                );
            }
          })}
        </div>
      </>
    )
  );
};

const SameDayBlock = () => {
  // same day spedition
  const initTime = new Date();
  const [isSameDay, setIsSameDay] = useState(!hasPassedLimitTime(initTime));
  const [time, setTime] = useState({
    h: initTime.getHours(),
    m: initTime.getMinutes(),
    s: initTime.getSeconds(),
  });

  useEffect(() => {
    // timer setup for same spedition
    const d = new Date();
    if (!hasPassedLimitTime(d)) {
      const intval = setInterval(() => {
        const now = new Date();
        if (hasPassedLimitTime(now)) {
          setIsSameDay(false);
        }
        setTime(getTimeDiff(now));
      }, 500);
      return () => {
        clearInterval(intval);
      };
    }
  }, []);

  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  return (
    isSameDay && (
      <p className="text-sm mt-4">
        {t["ordina-entro-1"]}{" "}
        <span className="font-bold">
          {time.h}h, {time.m}min {t.e} {time.s}sec
        </span>{" "}
        {t["ordina-entro-2"]}
      </p>
    )
  );
};

export default BuyInfo;
