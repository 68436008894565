import React from "react";

const LinedTitle = ({ children }) => (
  <div className="flex items-center gap-1 justify-center">
    <div className="h-px bg-ci-gray-light flex-grow" />
    {children}
    <div className="h-px bg-ci-gray-light flex-grow" />
  </div>
);

export default LinedTitle;
