import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import Stars from "../commons/stars";

const TopInfo = ({ tit, sub, desc, avgRating, numReviews }) => {
  const { t, locale } = useContext(LanguageContext);
  const l = locale;
  return (
    <div>
      <div className="flex justify-between items-center w-full">
        <h1 className="ci-h2">{tit}</h1>
        {/* TODO: get qt dynamically + showing logic */}
        {/* <div className="ci-badge text-white bg-ci-blue">
        Ultimi 9 kit disponibili
      </div> */}
      </div>
      <div className="flex justify-between items-center w-full mt-3 flex-wrap">
        <h1 className="font-bold w-full sm:w-auto">{sub}</h1>
        {/* TODO: get reviews */}
        {numReviews && (
          <div className="flex items-center">
            <Stars rating={avgRating} />
            <p className="ml-3">
              {numReviews} {t["recension" + (numReviews === 1 ? "e" : "i")]}
            </p>
          </div>
        )}
      </div>
      <div className="mt-4">{desc}</div>
      <div className="my-4 h-px bg-ci-beige-50" />
    </div>
  );
};

TopInfo.defaultProps = {
  numReviews: null,
};

export default TopInfo;
