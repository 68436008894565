import { graphql, Link } from "gatsby";
import React, { useMemo } from "react";
import { useContext } from "react";
import Breadcrumb from "../components/commons/breadcrumb";
import Layout from "../components/layout/layout";
import BuyInfo from "../components/product/buy-info";
import TopInfo from "../components/product/top-info";
import { getBlokComponent } from "../components/storyblok/get-blok";
import useBasePagesSEO from "../components/use-base-pages-seo";
import { getTranslatedIfAvail, parseStory } from "../components/utils";
import { LanguageContext } from "../context/language-context";
import { StoreContext } from "../context/store-context";

const story2order = (a) =>
  parseInt(
    a?.tag_list.filter((t) => t.startsWith("ord-")[0]?.slice(4) ?? "100000")
  );

const Product = ({ data }) => {
  const story = data?.storyblok ? parseStory(data.storyblok) : {};
  const extraBloks = useMemo(
    () =>
      (data?.bloks?.nodes ?? [])
        .map(parseStory)
        .sort((a, b) => story2order(b) - story2order(a)),
    [data.bloks]
  );
  const assets = story?.content?.assets ?? [];

  const { pageInfo } = useBasePagesSEO();
  const { t, locale } = useContext(LanguageContext);
  const l = locale;

  let cat = "",
    catUrl = "";
  data?.shopify?.tags.forEach((t) => {
    if (t === "kit") {
      const { title_seo, url } = pageInfo["Kit"][l];
      cat = title_seo;
      catUrl = url;
    } else if (t === "accessori") {
      const { title_seo, url } = pageInfo["Accessori"][l];
      cat = title_seo;
      catUrl = url;
    }
  });

  const avgRating = 9;
  const numReviews = 12;

  // ENG PRODS STORE
  const { translatedProds } = useContext(StoreContext);
  const getT = (sku) =>
    translatedProds[l] && translatedProds[l][sku.toLowerCase()]?.title;

  const tit = getTranslatedIfAvail(
    data.shopify.title,
    getT(data.shopify.handle),
    true
  );
  const sub = getTranslatedIfAvail(
    data.shopify.title,
    getT(data.shopify.handle),
    false
  );

  //console.log({ story, shopi: data.shopify, extraBloks });
  return (
    <Layout>
      <Breadcrumb>
        <Link to="/">
          <p className="text-ci-gray hover:text-ci-black transition-colors">
            Home
          </p>
        </Link>
        <Link to={catUrl}>
          <p className="text-ci-gray hover:text-ci-black transition-colors">
            {cat}
          </p>
        </Link>
        <p>{tit}</p>
      </Breadcrumb>
      <section className="ci-cont mt-12 flex flex-wrap">
        <div className="w-full md:w-7/12 md:pr-8 flex flex-col gap-8 order-4 md:order-none">
          {/* TODO: handle videos as well */}
          {assets.map((a) => (
            <img src={a?.filename ?? ""} alt={a?.alt ?? ""} />
          ))}
        </div>
        <div className="w-full md:w-5/12">
          <TopInfo
            tit={tit}
            sub={sub}
            desc={story?.content?.desc ?? ""}
            avgRating={avgRating}
            numReviews={tit.length}
          />
          <BuyInfo
            product={data.shopify}
            tit={tit}
            type={story?.content?.variants_type ?? "image"}
          />
        </div>
      </section>
      <section className="mb-20">
        {(story?.content?.blocks ?? []).map((blok) => getBlokComponent(blok))}
        {(extraBloks ?? []).map((story) => getBlokComponent(story.content))}
      </section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ProdottoPage($id: String!, $sku: String!, $lang: String!) {
    shopify: shopifyProduct(id: { eq: $id }) {
      handle
      description
      images {
        altText
        gatsbyImageData(layout: FULL_WIDTH)
      }
      title
      tags
      shopifyId
      variants {
        sku
        availableForSale
        shopifyId
        inventoryQuantity
        compareAtPrice
        price
        id
        title
      }
    }
    storyblok: storyblokEntry(lang: { eq: $lang }, slug: { eq: $sku }) {
      content
      tag_list
      name
      slug
    }
    bloks: allStoryblokEntry(
      filter: { lang: { eq: $lang }, tag_list: { in: [$sku] } }
    ) {
      nodes {
        slug
        content
        tag_list
      }
    }
  }
`;

export default Product;
