import React from "react";
import arrR from "../../resources/images/icon_arrow_right.svg";
import arrRGray from "../../resources/images/icon_arrow_right_gray.svg";

const Breadcrumb = ({ children }) => (
  <div className="bg-ci-beige-light">
    <div className="ci-cont py-2.5 flex">
      {children.map((ch, i) => (
        <>
          {i > 0 && (
            <img
              src={i === children.length - 1 ? arrRGray : arrR}
              alt=""
              className="w-4 mx-2"
            />
          )}
          {ch}
        </>
      ))}
    </div>
  </div>
);

export default Breadcrumb;
